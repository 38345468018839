import type { ColumnDef, Row } from "@tanstack/react-table"
import * as React from "react";
import { z } from "zod"

import { Signature } from "innoculator-models-specification-ts/core";
import type { Attribute } from "common-models-ts";
import { CommonForm, CommonFormField, CommonFormInputType } from "app-libs-ui/components";
import { EditorFormProps, TypeSpec } from "app-libs-ui/util";

import {CommonTabs, CommonTabsProps, getActionsColumn, getColumn, getRowNumberColumn, WorkbenchParams} from "app-libs-ui/components";

const specification = Signature.specification;
const attributes = specification.attributes as { [p: string]: Attribute };


const {
    name,
    description,
    data,
    version,
} = attributes;

const tagLine = "Signatures are generated by Innoculator to patch security vulnerabilities.";

/**
    Schema to validate the editor form
 */
const editorSchema = z.object({
    name: z.string().min(2),
    description: z.any(),
})

const getListColumns = (wbParams: WorkbenchParams): ColumnDef<Signature>[] => {
    return [
        getRowNumberColumn<Signature>(wbParams),
        getColumn<Signature>(wbParams, name.name!, name.label!, name.type!, 250),
        getColumn<Signature>(wbParams, description.name!, description.label!, description.type!, 150),
        getColumn<Signature>(wbParams, version.name!, version.label!, version.type!, 150),
        getActionsColumn(),
    ]
}

function EditorForm(props: EditorFormProps<Signature>) {
    const {
        form,
        data:_data,
        onSubmit,
        onCancel,
    } = props;

    return (
        <CommonForm
            instance={_data}
            form={form}
            onSubmit={onSubmit}
            onCancel={onCancel}
        >
            <CommonFormField
                name={name.name!}
                label={name.label!}
                description={name.description!}
                inputType={CommonFormInputType.Input}
                form={form}
                hideSeparator
            />
            <CommonFormField
                name={description.name!}
                label={description.label!}
                description={description.description!}
                inputType={CommonFormInputType.Textarea}
                form={form}
                hideSeparator
            />
            <CommonFormField
                name={data.name!}
                label={data.label!}
                description={data.description!}
                inputType={CommonFormInputType.Textarea}
                form={form}
                valueClass={"h-[300px]"}         
                hideSeparator
            />
            <CommonFormField
                name={version.name!}
                label={version.label!}
                description={version.description!}
                inputType={CommonFormInputType.Input}
                form={form}
                hideSeparator
            />
        </CommonForm>
    )
}

function TypeTabs({className, selected}: React.HTMLAttributes<HTMLElement> & CommonTabsProps) {
    return (
        <CommonTabs
            className={className}
            selected={selected}
            tabs={[
                {
                    name: "properties",
                    label: "Properties",
                    linkTo: "./properties",
                    icon: "rr-settings-sliders",
                },
            ]}
        />
    )
}

export const SignatureTypeSpec: TypeSpec = {
    EditorForm,
    getListColumns,
    editorSchema,
    TypeTabs,
    tagLine,
    specification,
    attributes,
}
import type { ColumnDef, Row } from "@tanstack/react-table"
import * as React from "react";
import { z } from "zod"

import { WorkloadRuleset, Sensor, SensorState } from "innoculator-models-specification-ts/core";
import type { Attribute } from "common-models-ts";
import { CommonForm, CommonFormField, CommonFormInputType } from "app-libs-ui/components";
import { EditorFormProps, TypeSpec } from "app-libs-ui/util";

import {CommonTabs, CommonTabsProps, getActionsColumn, getColumn, getRowNumberColumn, WorkbenchParams} from "app-libs-ui/components";

const specification = Sensor.specification;
const attributes = specification.attributes as { [p: string]: Attribute };


const {
    name,
    description,
    associatedSensorId,
    associatedSensorName,
    associatedWorkloadRulesetId,
    associatedWorkloadRulesetName,
    signatureChecksum,
} = attributes;

const tagLine = "Sensors are edge devices that monitor application traffic";

/**
    Schema to validate the editor form
 */
const editorSchema = z.object({
    name: z.string().min(2),
    description: z.any().nullable(),
    associatedWorkloadRulesetId: z.string(),
})

const getListColumns = (wbParams: WorkbenchParams): ColumnDef<Sensor>[] => {
    return [
        getRowNumberColumn<Sensor>(wbParams),
        getColumn<Sensor>(wbParams, name.name!, name.label!, name.type!, 250),
        getColumn<Sensor>(wbParams, description.name!, description.label!, description.type!, 150),
        getColumn<Sensor>(wbParams, associatedWorkloadRulesetName.name!, associatedWorkloadRulesetName.label!, associatedWorkloadRulesetName.type!, 150),
        getColumn<Sensor>(wbParams, associatedSensorName.name!, associatedSensorName.label!, associatedSensorName.type!, 150),
        getActionsColumn(),
    ]
}

function EditorForm(props: EditorFormProps<Sensor>) {
    const {
        form,
        data,
        onSubmit,
        onCancel,
        relatedInstances,
    } = props;

    return (
        <CommonForm
            instance={data}
            form={form}
            onSubmit={onSubmit}
            onCancel={onCancel}
        >
            <CommonFormField
                name={name.name!}
                label={name.label!}
                description={name.description!}
                inputType={CommonFormInputType.Input}
                form={form}
                hideSeparator
            />
            <CommonFormField
                name={description.name!}
                label={description.label!}
                description={description.description!}
                inputType={CommonFormInputType.Textarea}
                form={form}
                hideSeparator
            />
            <CommonFormField
                name={associatedSensorId.name!}
                label={associatedSensorId.label!}
                description={associatedSensorId.description!}
                inputType={CommonFormInputType.RelatedInstanceSelect}
                values={relatedInstances && Object.values(relatedInstances[Sensor.specification.name!])}
                {...props}
                hideSeparator
            />

            <CommonFormField
                name={associatedWorkloadRulesetId.name!}
                label={associatedWorkloadRulesetId.label!}
                description={associatedWorkloadRulesetId.description!}
                inputType={CommonFormInputType.RelatedInstanceSelect}
                values={relatedInstances && Object.values(relatedInstances[WorkloadRuleset.specification.name!])}
                {...props}
                hideSeparator
            />
            <CommonFormField
                name={signatureChecksum.name!}
                label={signatureChecksum.label!}
                description={signatureChecksum.description!}
                inputType={CommonFormInputType.Input}
                disabled
                form={form}
                hidden={(data: any) => {
                    return !data.signatureChecksum;
                }}     
                hideSeparator
            />
        </CommonForm>
    )
}

function TypeTabs({className, selected}: React.HTMLAttributes<HTMLElement> & CommonTabsProps) {
    return (
        <CommonTabs
            className={className}
            selected={selected}
            tabs={[
                {
                    name: "properties",
                    label: "Properties",
                    linkTo: "./properties",
                    icon: "rr-settings-sliders",
                },
            ]}
        />
    )
}

const relatedInstanceSpecificationNames: Array<string> = [
    Sensor.specification.name as string,
    WorkloadRuleset.specification.name as string,
]

export const WorkloadTypeSpec: TypeSpec = {
    EditorForm,
    getListColumns,
    editorSchema,
    TypeTabs,
    tagLine,
    relatedInstanceSpecificationNames,
    specification,
    attributes,
}
import * as React from "react";
import { CommonTabs, CommonTabsProps } from "app-libs-ui/components";
import { CategorySpec } from "app-libs-ui/util";

export const tagline = "Applications are monitored by Innoculator for Vulnerabilities.";
export const label = "Applications";

export function CategoryTabs({ className, selected }: React.HTMLAttributes<HTMLElement> & CommonTabsProps) {
    return (
        <CommonTabs
            className={className}
            selected={selected}
            tabs={[
                {
                    name: "instances",
                    label: "Workloads",
                    linkTo: "./../instances",
                    icon: "rr-key",
                },
                {
                    name: "rulesets",
                    label: "Workload Rulesets",
                    linkTo: "./../rulesets",
                    icon: "rr-key",
                },
            ]}
        />
    )
}

export const WorkloadCategorySpec: CategorySpec = {
    tagline,
    label,
    CategoryTabs,
}

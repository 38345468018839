import React from "react";
import { SidebarSectionsProps, SidebarSection, SidebarItem } from "app-libs-ui/components";


export function SideBarSections({ sectionClass, itemClass, mode, screenMode, selectedDeployment, baseRoute, workbenchRoute, wbParams }: SidebarSectionsProps) {
    return (
        <>
            <SidebarSection
                className={sectionClass}
                header="Policy"
                compactHeader="P"
                mode={mode}
            >
                <SidebarItem
                    className={itemClass}
                    flatIcon="rr-file-signature"
                    label="Signatures"
                    action={`${workbenchRoute}/signatures`}
                    mode={mode}
                    disabled={!selectedDeployment}
                    selected={wbParams.category === "signatures"}
                />
                <SidebarItem
                    className={itemClass}
                    flatIcon="rr-apps"
                    label="Applications"
                    action={`${workbenchRoute}/applications`}
                    mode={mode}
                    disabled={!selectedDeployment}
                    selected={wbParams.category === "applications"}
                />
                <SidebarItem
                    className={itemClass}
                    flatIcon="rr-sensor-on"
                    label="Sensors"
                    action={`${workbenchRoute}/sensors`}
                    mode={mode}
                    disabled={!selectedDeployment}
                    selected={wbParams.category === "sensors"}
                />
            </SidebarSection>
        </>
    )
}

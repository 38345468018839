import * as React from "react";
import { CommonTabs, CommonTabsProps } from "app-libs-ui/components";
import { CategorySpec } from "app-libs-ui/util";

export const tagline = "Signatures are generated by Innoculator to patch security vulnerabilities.";
export const label = "Signatures";

export function CategoryTabs({ className, selected }: React.HTMLAttributes<HTMLElement> & CommonTabsProps) {
    return (
        <CommonTabs
            className={className}
            selected={selected}
            tabs={[
                {
                    name: "instances",
                    label: "Signatures",
                    linkTo: "./../instances",
                    icon: "sr-file-signature",
                },
            ]}
        />
    )
}

export const SignatureCategorySpec: CategorySpec = {
    tagline,
    label,
    CategoryTabs,
}

import type { ColumnDef, Row } from "@tanstack/react-table"
import * as React from "react";
import { z } from "zod"

import { SensorState } from "innoculator-models-specification-ts/core";
import type { Attribute } from "common-models-ts";
import { CommonForm, CommonFormField, CommonFormInputType } from "app-libs-ui/components";
import { EditorFormProps, TypeSpec } from "app-libs-ui/util";

import { CommonTabs, CommonTabsProps, getActionsColumn, getColumn, getRowNumberColumn, WorkbenchParams } from "app-libs-ui/components";

const specification = SensorState.specification;
const attributes = specification.attributes as { [p: string]: Attribute };

const {
    dateTime,
    systemInfo,
} = attributes;

const tagLine = "A Sensor Telemetry record.";

/**
    Schema to validate the editor form
 */
const editorSchema = z.object({
    [dateTime.name!]: z.string(),
    [systemInfo.name!]: z.string(),
})

const getListColumns = (wbParams: WorkbenchParams): ColumnDef<SensorState>[] => {
    return [
        getRowNumberColumn<SensorState>(wbParams),
        getColumn<SensorState>(wbParams, dateTime.name!, dateTime.label!, dateTime.type!, 250),
        getActionsColumn(),
    ]
}

function EditorForm(props: EditorFormProps<SensorState>) {
    const {
        form,
        data,
        onSubmit,
        onCancel,
    } = props;

    return (
        <CommonForm
            instance={data}
            form={form}
            onSubmit={onSubmit}
            onCancel={onCancel}
        >
            <CommonFormField
                name={dateTime.name!}
                label={dateTime.label!}
                description={dateTime.description!}
                inputType={CommonFormInputType.Input}
                disabled
                form={form}
            />
            <CommonFormField
                name={systemInfo.name!}
                label={systemInfo.label!}
                description={systemInfo.description!}
                inputType={CommonFormInputType.JSON}
                disabled
                form={form}
            />
        </CommonForm>
    )
}

function TypeTabs({ className, selected, instance }: React.HTMLAttributes<HTMLElement> & CommonTabsProps) {
    return (
        <CommonTabs
            className={className}
            selected={selected}
            tabs={[
                {
                    name: "properties",
                    label: "Properties",
                    linkTo: "./properties",
                    icon: "rr-settings-sliders",
                },
            ]}
        />
    )
}

export const SensorStateTypeSpec: TypeSpec = {
    EditorForm,
    getListColumns,
    editorSchema,
    TypeTabs,
    tagLine,
    specification,
    attributes,
    disableCreateInstance: true,
}
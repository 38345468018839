import { TestObject, TestObjectRelated } from "app-models-specification-ts/core";

import { CategorySpecDetailsMap, TypeSpecDetailsMap } from "app-libs-ui/util";
import { SensorCategorySpec } from "./specification/category/SensorCategorySpec.js";
import { Sensor, SensorAPIKey, SensorState, Signature, WorkloadRuleset, SignatureMapping, Workload } from "innoculator-models-specification-ts/core";
import { SensorTypeSpec } from "./specification/type/SensorTypeSpec.js";
import { SensorStateTypeSpec } from "./specification/type/SensorStateTypeSpec.js";
import { SignatureCategorySpec } from "./specification/category/SignatureCategorySpec.js";
import { SignatureTypeSpec } from "./specification/type/SignatureTypeSpec.js";
import { SignatureMappingTypeSpec } from "./specification/type/SignatureMappingTypeSpec.js";
import { WorkloadRulesetTypeSpec } from "./specification/type/WorkloadRulesetTypeSpec.js";
import { SensorAPIKeyTypeSpec } from "./specification/type/SensorAPIKeyTypeSpec.js";
import { WorkloadCategorySpec } from "./specification/category/WorkloadCategorySpec.js";
import { WorkloadTypeSpec } from "./specification/type/WorkloadType.js";

export const categorySpecs: CategorySpecDetailsMap = {
    workloads: {
        category: "workloads",
        categorySpec: WorkloadCategorySpec,
        defaultType: "policies",
        types: {
            instances: {
                type: "instances",
                specification: Workload,
                typeSpec: WorkloadTypeSpec,
            },
            rulesets: {
                type: "rulesets",
                specification: WorkloadRuleset,
                typeSpec: WorkloadRulesetTypeSpec,
                related: {
                    signaturemappings: {
                        type: "signaturemappings",
                        specification: SignatureMapping,
                        typeSpec: SignatureMappingTypeSpec,
                    }
                }
            }
        }
    },
    signatures: {
        category: "signatures",
        categorySpec: SignatureCategorySpec,
        defaultType: "instances",
        types: {
            instances: {
                type: "instances",
                specification: Signature,
                typeSpec: SignatureTypeSpec,
            },
        }
    },
    sensors: {
        category: "sensors",
        categorySpec: SensorCategorySpec,
        defaultType: "instances",
        types: {
            instances: {
                type: "instances",
                specification: Sensor,
                typeSpec: SensorTypeSpec,
                related: {
                    sensorapikeys: {
                        type: "sensorapikeys",
                        specification: SensorAPIKey,
                        typeSpec: SensorAPIKeyTypeSpec,
                    },
                    sensorstates: {
                        type: "sensorstates",
                        specification: SensorState,
                        typeSpec: SensorStateTypeSpec,
                    }
                }
            }
        }
    },
}

export const typeSpecs: TypeSpecDetailsMap = {
    [Sensor.specification.name!]: SensorTypeSpec,
    [SensorState.specification.name!]: SensorStateTypeSpec,
    [Signature.specification.name!]: SignatureTypeSpec,
    [WorkloadRuleset.specification.name!]: WorkloadRulesetTypeSpec,
    [SignatureMapping.specification.name!]: SignatureMappingTypeSpec,
};


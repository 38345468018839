import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AppContainer } from "app-libs-ui/components";
import { store } from "app-libs-ui/redux";
import { UserService } from "app-libs-ui/services";

import "@flaticon/flaticon-uicons/css/all/all.css";

import './index.css';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux'

// Custom imports:
import { SideBarSections } from "./model/custom/sidebar/SideBarSections.js";
import { typeSpecs } from "./model/custom/index.js";
import { categorySpecs } from "./model/custom/index.js";
// End Custom imports

const appContainer = document.getElementById('app');
const root = createRoot(appContainer!);


export const renderApp = () => root.render(
    <Router>
        <Provider store={store}>
            <AppContainer 
                typeSpecs={typeSpecs}
                categorySpecs={categorySpecs}
                sideBarSections={SideBarSections}
                defaultCategory="signatures"
            />
        </Provider>
    </Router>
);

UserService.initKeycloak(renderApp);
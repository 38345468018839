import type { ColumnDef, Row } from "@tanstack/react-table"
import * as React from "react";
import { z } from "zod"

import { WorkloadRuleset, SignatureMapping } from "innoculator-models-specification-ts/core";
import type { Attribute } from "common-models-ts";
import { CommonForm, CommonFormField, CommonFormInputType } from "app-libs-ui/components";
import { EditorFormProps, TypeSpec } from "app-libs-ui/util";

import {CommonTabs, CommonTabsProps, getActionsColumn, getColumn, getRowNumberColumn, WorkbenchParams} from "app-libs-ui/components";

const specification = WorkloadRuleset.specification;
const attributes = specification.attributes as { [p: string]: Attribute };


const {
    name,
    description,
    signatures,
    checksum,
} = attributes;

const label = "Application Policies";
const tagLine = "Application Policies group Signatures to be applied to Sensors.";

/**
    Schema to validate the editor form
 */
const editorSchema = z.object({
    name: z.string().min(2),
    description: z.any(),
})

const getListColumns = (wbParams: WorkbenchParams): ColumnDef<WorkloadRuleset>[] => {
    return [
        getRowNumberColumn<WorkloadRuleset>(wbParams),
        getColumn<WorkloadRuleset>(wbParams, name.name!, name.label!, name.type!, 250),
        getColumn<WorkloadRuleset>(wbParams, description.name!, description.label!, description.type!, 150),
        getActionsColumn(),
    ]
}

function EditorForm(props: EditorFormProps<WorkloadRuleset>) {
    const {
        form,
        data:_data,
        onSubmit,
        onCancel,
    } = props;

    return (
        <CommonForm
            instance={_data}
            form={form}
            onSubmit={onSubmit}
            onCancel={onCancel}
        >
            <CommonFormField
                name={name.name!}
                label={name.label!}
                description={name.description!}
                inputType={CommonFormInputType.Input}
                form={form}
                hideSeparator
            />
            <CommonFormField
                name={description.name!}
                label={description.label!}
                description={description.description!}
                inputType={CommonFormInputType.Textarea}
                form={form}
                hideSeparator
            />
            <CommonFormField
                name={signatures.name!}
                label={signatures.label!}
                description={signatures.description!}
                inputType={CommonFormInputType.Textarea}
                form={form}
                hidden={(data: any) => {
                    return !data.signatures;
                }}
                disabled
                hideSeparator
            />
            <CommonFormField
                name={checksum.name!}
                label={checksum.label!}
                description={checksum.description!}
                inputType={CommonFormInputType.Input}
                form={form}
                hidden={(data: any) => {
                    return !data.checksum;
                }}
                disabled
                hideSeparator
            />
        </CommonForm>
    )
}

const relatedInstanceSpecificationNames: Array<string> = [
    SignatureMapping.specification.name as string,
]

function TypeTabs({className, selected}: React.HTMLAttributes<HTMLElement> & CommonTabsProps) {
    return (
        <CommonTabs
            className={className}
            selected={selected}
            tabs={[
                {
                    name: "properties",
                    label: "Properties",
                    linkTo: "./properties",
                    icon: "rr-settings-sliders",
                },
                {
                    name: "signaturemappings",
                    label: "Signature Mappings",
                    linkTo: "./related/signaturemappings",
                    icon: "rr-exchange",
                },
            ]}
        />
    )
}

export const WorkloadRulesetTypeSpec: TypeSpec = {
    EditorForm,
    getListColumns,
    editorSchema,
    TypeTabs,
    tagLine,
    label,
    specification,
    attributes,
    relatedInstanceSpecificationNames,
}
import type { ColumnDef, Row } from "@tanstack/react-table"
import * as React from "react";
import { z } from "zod"

import { Signature, WorkloadRuleset, SignatureMapping } from "innoculator-models-specification-ts/core";
import type { Attribute } from "common-models-ts";
import { CommonForm, CommonFormField, CommonFormInputType } from "app-libs-ui/components";
import { EditorFormProps, TypeSpec } from "app-libs-ui/util";

import { CommonTabs, CommonTabsProps, getActionsColumn, getColumn, getRowNumberColumn, WorkbenchParams } from "app-libs-ui/components";

const specification = SignatureMapping.specification;
const attributes = specification.attributes as { [p: string]: Attribute };

const {
    associatedWorkloadRulesetName,
    associatedWorkloadRulesetId,
    associatedSignatureName,
    associatedSignatureId,
} = attributes;

const tagLine = "A mapping of Signatures to Application Policies.";

/**
    Schema to validate the editor form
 */
const editorSchema = z.object({
    [associatedSignatureId.name!]: z.string(),
})

const getListColumns = (wbParams: WorkbenchParams): ColumnDef<SignatureMapping>[] => {
    return [
        getRowNumberColumn<SignatureMapping>(wbParams),
        getColumn<SignatureMapping>(wbParams, associatedWorkloadRulesetName.name!, associatedWorkloadRulesetName.label!, associatedWorkloadRulesetName.type!, 250),
        getColumn<SignatureMapping>(wbParams, associatedSignatureName.name!, associatedSignatureName.label!, associatedSignatureName.type!, 250),
        getActionsColumn(),
    ]
}

function EditorForm(props: EditorFormProps<SignatureMapping>) {
    const {
        form,
        data,
        onSubmit,
        onCancel,
        relatedInstances,
    } = props;

    return (
        <CommonForm
            instance={data}
            form={form}
            onSubmit={onSubmit}
            onCancel={onCancel}
        >
            <CommonFormField
                name={associatedSignatureId.name!}
                label={associatedSignatureId.label!}
                description={associatedSignatureId.description!}
                inputType={CommonFormInputType.RelatedInstanceSelect}
                values={relatedInstances && Object.values(relatedInstances[Signature.specification.name!])}
                {...props}
            />
        </CommonForm>
    )
}

const relatedInstanceSpecificationNames: Array<string> = [
    WorkloadRuleset.specification.name as string,
    Signature.specification.name as string,
]

function TypeTabs({ className, selected, instance }: React.HTMLAttributes<HTMLElement> & CommonTabsProps) {
    return (
        <CommonTabs
            className={className}
            selected={selected}
            tabs={[
                {
                    name: "properties",
                    label: "Properties",
                    linkTo: "./properties",
                    icon: "rr-settings-sliders",
                },
            ]}
        />
    )
}

export const SignatureMappingTypeSpec: TypeSpec = {
    EditorForm,
    getListColumns,
    editorSchema,
    TypeTabs,
    tagLine,
    specification,
    attributes,
    relatedInstanceSpecificationNames,
}